:root {
  --c-primary: #481111;
  --c-primary-500: #591212;
}

.i-hero-slide__body-pattern {
  --bg-color: var(--c-primary-500);
  --pattern-color: var(--c-primary);
}

.i-hero-slide .btn.--black {
  --bg-color: #cfb53b;
  --font-color: #292934;
}

.m-header-top, .main-footer {
  --border-color: #cfb53b;
}

/*# sourceMappingURL=kubey.css.map */
